var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm mới thời gian gửi tin",
        visible: _vm.dialogVisible,
        width: "550px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "updateData", attrs: { model: _vm.fromData, rules: _vm.rules } },
        [
          _c(
            "div",
            [
              _c("el-time-picker", {
                staticStyle: { "margin-right": "5px" },
                attrs: {
                  clearable: false,
                  type: "time",
                  "picker-options": {
                    selectableRange: "05:00:00 - 22:00:00",
                  },
                  "value-format": "HH:mm",
                  format: "HH:mm",
                  placeholder: "Chọn thời gian",
                },
                model: {
                  value: _vm.fromData.time,
                  callback: function ($$v) {
                    _vm.$set(_vm.fromData, "time", $$v)
                  },
                  expression: "fromData.time",
                },
              }),
              _vm.dateNotify.type != "attendanceArrive" &&
              _vm.dateNotify.type != "attendanceLeave" &&
              _vm.dateNotify.type != "attendanceEat" &&
              _vm.dateNotify.type != "home" &&
              _vm.dateNotify.type != "medicine" &&
              _vm.dateNotify.type != "message" &&
              _vm.dateNotify.type != "absent" &&
              _vm.dateNotify.type != "birthday"
                ? _c(
                    "el-select",
                    {
                      staticClass: "input-time",
                      attrs: { clearable: "", placeholder: "Chọn ngày" },
                      model: {
                        value: _vm.fromData.day,
                        callback: function ($$v) {
                          _vm.$set(_vm.fromData, "day", $$v)
                        },
                        expression: "fromData.day",
                      },
                    },
                    _vm._l(_vm.dayList, function (item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.value, value: item.key },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.dateNotify.typeReceive == "plus" &&
              (_vm.dateNotify.type == "fees" || _vm.dateNotify.type == "salary")
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "percent", label: "Phần trăm (%)" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 1,
                          max: 100,
                          placeholder: "Nhập phần trăm",
                        },
                        model: {
                          value: _vm.fromData.percent,
                          callback: function ($$v) {
                            _vm.$set(_vm.fromData, "percent", $$v)
                          },
                          expression: "fromData.percent",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("updateData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("updateData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }